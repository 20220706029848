import React, { useState, useEffect } from "react";
import {
  CreditCard,
  Gift,
  Gem,
  Users,
  ChevronRight,
  ChevronLeft,
  Star,
  Store,
  Armchair,
} from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const features = [
  {
    icon: Store,
    title: "Store",
    description: "Look around our Store",
    link: "/stores",
  },
  {
    icon: Gem,
    title: "Rewards",
    description: "Redeem points",
    link: "/reward",
  },
  {
    icon: Armchair,
    title: "Guide",
    description: "How to use our products",
    link: "/guide",
  },
  {
    icon: Users,
    title: "Booking",
    description: "Reserve your schedule",
    link: "/reservation/all",
  },
];

// ..

const bannerSlides = [
  {
    id: 1,
    image: "/test-1.jpg",
    title: "Exclusive Benefits",
    subtitle: "Experience Premium Massage Bed & cafe In West Hollywood",
    link: "/reservation",
  },
  {
    id: 2,
    image: "/test-2.jpg",
    title: "Premium Services",
    subtitle: "Tailored for your success",
    link: "/benefits",
  },
  {
    id: 3,
    image: "/test-3.jpg",
    title: "VIP Events",
    subtitle: "Network with each CERAGEM store",
    link: "/stores",
  },
];

function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const handleViewAllActivities = () => {
    navigate("/activities");
  };

  const handleClick = (link) => {
    navigate(link);
  };

  const nextRewardPoint = user ? user.point ?? 0 : 0;

  const nextRewardPercentage =
    nextRewardPoint / 10 > 100 ? 100 : nextRewardPoint;

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % bannerSlides.length);
  };

  const goToPrevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + bannerSlides.length) % bannerSlides.length
    );
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    setIsAutoPlaying(false);
  };

  useEffect(() => {
    if (!isAutoPlaying) return;

    const timer = setInterval(goToNextSlide, 5000);
    return () => clearInterval(timer);
  }, [isAutoPlaying]);

  // useEffect(() => {
  //   // 5초마다 슬라이드 되게 interval 설정
  //   const timer = setInterval(() => {
  //     // * useState에서 인자 받는거는 현재 값임.
  //     setCurrentSlide((prevSlide) => (prevSlide + 1) % bannerSlides.length);
  //     // * 우린 지금 배너 슬라이드 길이가 3이잖아. prevslide 는 이전 슬라이드 인덱스로만 치면 총 인덱스 0,1,2 로 총 2임. 그래서 3이 되면 3으로 나눠서 나머지 값 반환이니까 다시 0 반환. 이런 원리로 작성했었음.
  //   }, 5000);
  //   return () => clearInterval(timer);

  //   // ? useEffect 반환값으로 클린업 함수 정의. 이 함수는 컴포넌트가 언마운트 되거나 의존성 배열이 변경될 때 호출하는거. 설정된 타이머 정리
  // }, []);
  // ? useEffect에서 빈 배열이면 처음 컴포넌트 랜더링 될떄만 실행되는거 알지?

  const recentActivities =
    user && user.activities ? user.activities.slice(-3).reverse() : [];
  // slice(-3) 배열의 끝에서 세 번째 요소부터 끝까지.
  // slice(3) 배열의 인덱스 3부터 끝까지의 요소를 포함.
  // 그리고 slice 배열 사용하면, 원 배열 내비두는거 알제?

  return (
    <div className="bg-gray-100 min-h-screen w-full m-0 ">
      {/* Full-width container for the banner */}
      <div className="w-full sm:px-4 px-0">
        {/* Sliding Banner Section */}
        <section className="relative p-0 m-0 overflow-hidden shadow-lg h-64 sm:h-80 md:h-96 mb-6 sm:rounded">
          {bannerSlides.map((slide, index) => (
            <div
              key={slide.id}
              className={`absolute inset-0 transition-opacity duration-1000 ${
                index === currentSlide ? "opacity-100" : "opacity-0"
              }`}
            >
              {/* Inset이란 무엇인가 ->
              inset-0 = top,left,right,bottom 다 0으로 하는거래.
              이게 부모가 relative고 자식이 absolute일때, top,right,bottom,left 다 0이면 부모 경계에 딱 맞게 생성된다네
            */}
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full h-full object-cover"
              />
              <div
                className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   console.log("현재 인덱스:", index);
                //   console.log("현재 id:", slide.id);
                //   console.log("현재 슬라이드:", currentSlide);
                //   console.log("현재 슬라이드 이름:", slide.title);
                //   console.log("이동할 링크:", slide.link);
                //   if (slide.id === currentSlide) {
                //     handleClick(slide.link);
                //   }
                // }}
              >
                <div className="text-center px-4">
                  <h2
                    className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2"
                    style={{ color: "var(--accent-color)" }}
                  >
                    {slide.title}
                  </h2>
                  <p className="text-lg sm:text-xl md:text-2xl text-white">
                    {slide.subtitle}
                  </p>
                </div>
              </div>
            </div>
          ))}
          <button
            onClick={goToPrevSlide}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity z-20"
            aria-label="Previous slide"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={goToNextSlide}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
            aria-label="Next slide"
          >
            <ChevronRight size={24} />
          </button>

          {/* Slide Indicators */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {bannerSlides.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-2 h-2 rounded-full transition-all ${
                  index === currentSlide
                    ? "bg-white w-4"
                    : "bg-white/50 hover:bg-white/75"
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </section>
      </div>

      {/* Content container */}
      <div className="max-w-6xl mx-auto px-4 space-y-6">
        {/* Quick Access Features */}
        <section className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          {features.map((feature, index) => (
            <a
              key={index}
              onClick={() => handleClick(feature.link)}
              rel="noopener noreferrer"
              className="block"
            >
              <div className="bg-white rounded-lg p-4 shadow-md flex flex-col items-center text-center cursor-pointer hover:bg-gray-50 transition-colors h-full">
                <feature.icon
                  size={32}
                  color="var(--highlight-color)"
                  className="mb-2"
                />
                <h3 className="font-semibold">{feature.title}</h3>
                <p className="text-xs text-gray-600">{feature.description}</p>
              </div>
            </a>
          ))}
        </section>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Recent Activity */}
          <section className="md:col-span-2 bg-white rounded-lg p-6 shadow-md">
            <h2
              className="text-2xl font-bold mb-4"
              style={{ color: "var(--highlight-color)" }}
            >
              Recent Store Activity
            </h2>
            <ul className="space-y-4">
              {recentActivities &&
                recentActivities.map((activity, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between border-b pb-2"
                  >
                    <div className="flex items-center space-x-4">
                      {activity.type === "purchase" && (
                        <CreditCard size={22} className="text-sky-400" />
                      )}
                      {activity.type === "reward" && (
                        <Gift size={22} color="var(--accent-color)" />
                      )}
                      {activity.type === "benefit" && (
                        <Star size={22} color="var(--highlight-color)" />
                      )}
                      <div>
                        <p className="font-semibold text-sm">
                          {activity.details}
                        </p>
                        <p className="text-xs text-gray-500">
                          {new Date(activity.date).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <ChevronRight size={20} className="text-gray-400" />
                  </li>
                ))}
            </ul>
            <button
              className="mt-4 text-sm font-semibold text-blue-600 hover:text-blue-800"
              onClick={handleViewAllActivities}
            >
              View All Activity
            </button>
          </section>

          {/* Right Sidebar */}
          <div className="space-y-6">
            {/* Points Summary */}
            <section className="bg-white rounded-lg p-6 shadow-md">
              <h2
                className="text-xl font-bold mb-2"
                style={{ color: "var(--highlight-color)" }}
              >
                Your Points
              </h2>
              <p className="text-3xl font-bold mb-2">
                {user ? user.point : "0"}
              </p>
              <div className="flex justify-between">
                <span className="text-blue-400 text-sm">
                  {user ? user.tier : "Not member"}
                </span>
              </div>
            </section>

            {/* Upcoming Reward */}
            <section className="bg-white rounded-lg p-6 shadow-md">
              <h2
                className="text-xl font-bold mb-2"
                style={{ color: "var(--highlight-color)" }}
              >
                Next Reward
              </h2>
              <p className="text-sm mb-2">You're close to your next reward!</p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-2">
                <div
                  className="bg-green-600 h-2.5 rounded-full"
                  style={{ width: `${nextRewardPercentage}%` }}
                ></div>
              </div>
              <p className="text-xs text-gray-600">
                {user?.point >= 400 ? 0 : 400 - nextRewardPoint} more points
                needed
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
