import React, { useState } from "react";
import {
  Store,
  Calendar,
  MessageCircle,
  Users,
  X,
  ChevronRight,
  Mail,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { sendEmailAction } from "../actions/authActions";
import { useDispatch, useSelector } from "react-redux";

export const forumTopics = [
  {
    title: "The Benefits of a Thermal Massage for Pain Relief",
    date: "June 6, 2024",
    link: "https://ceragemus.com/pages/blog-06-06-2024",
  },
  {
    title: "What to do (and What NOT TO) After a Massage",
    date: "May 27, 2024",
    link: "https://ceragemus.com/pages/blog-05-27-2024",
  },
  {
    title: "Should You Get a Massage if You're Sick?",
    date: "May 20, 2024",
    link: "https://ceragemus.com/pages/blog-05-20-2024",
  },
  {
    title: "What Causes Muscle Spasms in the Back",
    date: "May 13, 2024",
    link: "https://ceragemus.com/pages/blog-05-13-2024",
  },
  {
    title: "Should You Get a Massage if You're Sick?",
    date: "April 29, 2024",
    link: "https://ceragemus.com/pages/blog-04-29-2024",
  },
  {
    title: "Should You Get a Massage if You're Sick?",
    date: "April 22, 2024",
    link: "https://ceragemus.com/pages/blog-04-22-2024",
  },
  {
    title: "Does Massage Help Sciatica?",
    date: "April 15, 2024",
    link: "https://ceragemus.com/pages/blog-04-15-2024",
  },
];

export const featuredStore = [
  

  { id: 2, name: "WEST HOLLYWOOD", email: "weho@ceragemus.com" },
  { id: 3, name: "FULLERTON", email: "fullerton@ceragemus.com" },
  // {
  //   id: 4,
  //   name: "LOS ANGELES - 928 S Western Ave",
  //   email: "koreatownplaza@ceragemus.com",
  // },
  {
    id: 5,
    name: "LOS ANGELES - 833 S Western Ave",
    email: "rodeo.galleria@ceragemus.com",
  },
  { id: 6, name: "LA JOLLA", email: "lajolla@ceragemus.com" },
  { id: 7, name: "SAN JOSE", email: "sanjose@ceragemus.com" },
  { id: 8, name: "DEL AMO", email: "delamo@ceragemus.com" },

  { id: 9, name: "DENVER", email: "denver@ceragemus.com" },
  { id: 10, name: "NILES", email: "niles@ceragemus.com" },
  { id: 11, name: "PALISADES", email: "palpark@ceragemus.com" },
  { id: 12, name: "DALLAS", email: "dallas@ceragemus.com" },
  // { id: 13, name: "HAWAII", email: "hawaii@ceragemus.com" },
  // { id: 14, name: "MESA", email: "mesa@ceragemus.com" },
];

function Community() {
  const [selectedStore, setSelectedStore] = useState(null);
  const [emailForm, setEmailForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [emailStatus, setEmailStatus] = useState("");

  const user = useSelector((state) => state.auth.user);

  const events = [
    {
      title: "Free Massage and Beauty in West Hollywood!",
      date: "Oct 31, 2024",
      link: "/reservation",
      isInternal: true,
    },
    {
      title: "Enjoy a Wellness Experience at a Store Near you!",
      date: "Oct 5, 2024",
      link: "/stores",
      isInternal: true,
    },
    {
      title: "Discover the power of Cellunic for glowing skin.",
      date: "Sep 3, 2024",
      link: "https://ceragemus.com/collections/beauty-care?srsltid=AfmBOoqGbLs5cWZBmKtUfwq0qn7hfoM9ki5c2j2raYG475Nc_nfMAbIp",
      isInternal: false,
    },
  ];
  // const recentTitles = forumTopics.slice(0, 3).map(topic => topic.title);
  const recentForums = forumTopics.slice(0, 3);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewAllForums = () => {
    navigate("/allforums");
  };

  // const handleClick = (link) => {
  //   navigate(link);
  // };

  const handleEventClick = (event) => {
    if (event.isInternal) {
      navigate(event.link);
    } else {
      window.open(event.link, "_blank");
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setEmailStatus("Sending...");
    try {
      // TODO: ACTION 사용하자. 그냥 이메일 send 만 하는거라 DB에 저장할 필요는 없을듯
      await dispatch(
        sendEmailAction({
          email: emailForm.email,
          name: emailForm.name,
          message: emailForm.message,
          to: selectedStore.email,
          // to: "andy.jeong@ceragemus.com",
          // TODO: 나중에 스토어 이메일로 바꾸기.
        })
      );
      // const response = await api.post("/reservation/send", {
      //   // ...emailForm,
      //   email: user.email,
      //   name: user.name,
      //   message: emailForm.message,
      //   // to: selectedStore.email,
      //   to: "andy.jeong@ceragemus.com"
      // });

      setEmailStatus("Email sent successfully!");
      setEmailForm({ name: "", email: "", message: "" });
      setSelectedStore(null);
    } catch (error) {
      // console.error("Error sending email:", error);
      setEmailStatus("Failed to send email. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="max-w-4xl mx-auto space-y-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Community Hub</h1>

        {/* Upcoming Events Section */}
        <section className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
            <Calendar className="mr-4 text-blue-500" />{" "}
            <span className="text-xl">Ongoing Events</span>
          </h2>
          <div className="space-y-4">
            {events.map((event, index) => (
              <div
                key={index}
                onClick={() => handleEventClick(event)}
                className="flex justify-between items-center border-b pb-2 last:border-b-0 last:pb-0 cursor-pointer"
              >
                <div>
                  <h3 className="font-semibold text-gray-700 text-base">
                    {event.title}
                  </h3>
                  <p className="text-sm text-gray-500">{event.date}</p>
                </div>
                <ChevronRight className="text-gray-400" />
              </div>
            ))}
          </div>
          {/* <button className="mt-4 text-blue-500 hover:text-blue-600 font-semibold">
            View All Events
          </button> */}
        </section>

        {/* Discussion Forums Section */}
        <section className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
            <MessageCircle className="mr-4 text-green-500" />{" "}
            <span className="text-xl">Discussion Forums</span>
          </h2>
          <div className="space-y-4">
            {recentForums.map((topic, index) => (
              <a
                key={index}
                className="flex justify-between items-center border-b pb-2 last:border-b-0 last:pb-0"
                href={topic.link}
              >
                <div>
                  <h3 className="font-semibold text-gray-700 text-base">
                    {topic.title}
                  </h3>
                  <p className="text-sm text-gray-500">{topic.date}</p>
                </div>
                <ChevronRight className="text-gray-400" />
              </a>
            ))}
          </div>
          <button
            className="mt-4 text-green-500 hover:text-green-600 font-semibold"
            onClick={handleViewAllForums}
          >
            Explore Forums
          </button>
        </section>

        {/* Contact Email Section */}
        {/* Contact Email Section */}
        <section className="bg-white rounded-lg shadow-md p-4 sm:p-6">
          <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-3 sm:mb-4 flex items-center">
            <Users className="mr-2 sm:mr-4 text-purple-500" size={20} />
            <span className="text-lg sm:text-xl">Contact Email</span>
          </h2>
          <div className="space-y-4">
            {featuredStore.map((store, index) => (
              <div
                key={index}
                className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 border-b pb-4 last:border-b-0 last:pb-0"
              >
                <div className="flex-1 mb-2 sm:mb-0">
                  <div className="flex items-center">
                    <Store className="text-black mr-2" size={16} />
                    <h3 className="font-semibold text-gray-700 text-sm sm:text-base">
                      {store.name}
                    </h3>
                  </div>
                  <p className="text-xs sm:text-sm text-gray-500 mt-1">
                    {store.email}
                  </p>
                </div>
                <button
                  onClick={() => setSelectedStore(store)}
                  className="bg-purple-500 text-white p-2 rounded hover:bg-purple-600 transition duration-300 w-full sm:w-auto"
                >
                  <span className="flex items-center justify-center">
                    <Mail size={16} className="mr-2" />
                    <span className="text-sm">Contact</span>
                  </span>
                </button>
              </div>
            ))}
          </div>
        </section>
        {/* Email Form Modal */}
        {selectedStore && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 border-2 border-black">
            <div className="bg-white p-4 sm:p-8 rounded-lg w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg sm:text-xl font-bold">
                  Contact {selectedStore.name}
                </h3>
                <button
                  onClick={() => setSelectedStore(null)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X size={20} />
                </button>
              </div>
              <form onSubmit={handleEmailSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Your Name
                  </label>
                  <input
                    type="text"
                    value={emailForm.name}
                    onChange={(e) =>
                      setEmailForm({ ...emailForm, name: e.target.value })
                    }
                    // readOnly
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Your Email
                  </label>
                  <input
                    type="email"
                    value={emailForm.email}
                    onChange={(e) =>
                      setEmailForm({ ...emailForm, email: e.target.value })
                    }
                    // readOnly
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <textarea
                    value={emailForm.message}
                    onChange={(e) =>
                      setEmailForm({ ...emailForm, message: e.target.value })
                    }
                    required
                    rows="4"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500"
                  ></textarea>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setSelectedStore(null)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    Send Email
                  </button>
                </div>
              </form>
              {emailStatus && (
                <p className="mt-4 text-center text-sm font-medium text-green-600">
                  {emailStatus}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Community;
