import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SwipeNavigation({ children }) {
  const navigate = useNavigate();
  let touchStartX = 0;
  let touchStartY = 0;
  let touchEndX = 0;

  useEffect(() => {
    const handleTouchStart = (e) => {
      // 로딩 스피너가 있는지 확인
      const loadingSpinner = document.querySelector('.animate-spin');
      if (loadingSpinner) return;

      touchStartX = e.touches[0].clientX;
      touchStartY = e.touches[0].pageY;
    };

    const handleTouchEnd = (e) => {
      // 로딩 스피너가 있는지 다시 확인
      const loadingSpinner = document.querySelector('.animate-spin');
      if (loadingSpinner) return;

      touchEndX = e.changedTouches[0].clientX;
      handleSwipe();
    };

    const handleSwipe = () => {
      const screenWidth = window.innerWidth;
      const swipeDistance = touchEndX - touchStartX;
      const minSwipeDistance = screenWidth * 0.25;
      const edgeThreshold = 50;

      if (touchStartX <= edgeThreshold && 
          swipeDistance > minSwipeDistance && 
          navigator.userAgent.includes('flutter')) {
        navigate(-1);
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [navigate]);

  return <>{children}</>;
}

export default SwipeNavigation;